.CounsellorProfile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow-y: auto;
  width: 100%;
  background-color: inherit;
  position: relative;
  border: none;
  gap: 40px;
  height: 100%;
  padding: 50px 200px 50px 200px;
  .info-img {
    height: 100%;
  }
  .left-profile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .left-profile-middle {
      width: 100%;
      height: 100%;
      border: 1px solid #1111111a;
      background-color: var(--background-light);
      padding: 24px;
      border-radius: 20px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .row {
          display: flex;
          justify-content: start;
          .col {
            width: 100%;
            display: flex;
            .info-field {
              width: 100%;
              font-weight: 700;
            }
            .info-value {
              width: 100%;
              .gender-radio {
                display: flex;
                gap: 24px;
                .gender-text {
                  display: flex;
                  gap: 8px;
                }
              }
              input {
                z-index: 999;
                width: 100%;
                border-bottom: 1px solid #111;
              }
            }
          }
        }
      }
    }

    .left-profile-bottom {
      width: 100%;
      height: 100%;
      border: 1px solid #1111111a;
      background-color: var(--background-light);
      padding: 24px;
      border-radius: 20px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .row {
          display: flex;
          justify-content: start;
          .col {
            width: 100%;
            display: flex;
            .info-field {
              width: 100%;
              font-weight: 700;
            }
            .info-value {
              width: 100%;
              .gender-radio {
                display: flex;
                gap: 24px;
                .gender-text {
                  display: flex;
                  gap: 8px;
                }
              }
              input {
                z-index: 999;
                width: 100%;
                border-bottom: 1px solid #111;
              }
            }
          }
        }
      }
    }
    .documentsDetails {
      width: 100%;
      height: 100%;
      border: 1px solid #1111111a;
      background-color: var(--background-light);
      padding: 24px;
      border-radius: 20px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .info {
        .info {
          display: flex;
          flex-direction: column;
          gap: 12px;
          .row {
            display: flex;
            justify-content: start;
            .col {
              width: 100%;
              display: flex;
            }
          }
        }
      }
    }
  }

  .right-profile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 40px;

    .right-profile-buttons {
      display: flex;
      justify-content: space-between;
      border: 1px solid #1111111a;
      background-color: var(--background-light);
      padding: 24px;
      border-radius: 20px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .left {
        display: flex;
        gap: 12px;
        .save {
          background-color: var(--primary-color);
          border-radius: 20px;
          color: white;
          padding: 8px 12px;
          font-size: 12px;
          cursor: pointer;
        }
        .accept {
          background-color: green;
          border-radius: 20px;
          color: white;
          padding: 8px 12px;
          font-size: 12px;
          cursor: pointer;
        }
        .reject {
          background-color: red;
          border-radius: 20px;
          color: white;
          padding: 8px 12px;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .right-profile-info {
      border: 1px solid #1111111a;
      padding: 24px;
      border-radius: 20px;
      height: 100%;
      background-color: var(--background-light);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .row {
          display: flex;
          justify-content: start;
          .col {
            width: 100%;
            display: flex;
            .info-field {
              width: 100%;
              font-weight: 700;
            }
            .info-value {
              width: 100%;
              .gender-radio {
                display: flex;
                gap: 24px;
                .gender-text {
                  display: flex;
                  gap: 8px;
                }
              }
              input {
                z-index: 999;
                width: 100%;
                border-bottom: 1px solid #111;
              }
            }
          }
        }
      }
    }
    .bankDetails {
      border: 1px solid #1111111a;
      padding: 24px;
      border-radius: 20px;
      height: 100%;
      background-color: var(--background-light);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .row {
      display: flex;
      justify-content: start;
      .col {
        width: 100%;
        display: flex;
        .info-field {
          width: 100%;
          font-weight: 700;
          .info-value {
            width: 100%;
          }
        }
      }
    }
  }
}

.reason-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .dialog-content {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    .btns {
      display: flex;
      gap: 8px;
    }
    h3 {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    textarea {
      width: 100%;
      height: 100px;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      resize: vertical;
    }

    button {
      background: #3498db;
      color: #fff;
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;

      &:hover {
        background: #2980b9;
      }
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 1.2rem;
      cursor: pointer;
      color: #555;

      &:hover {
        color: #333;
      }
    }
  }
}
.document-col {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}
